import React, { useEffect, useState } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import i18n from 'i18next'
import ReactGA from 'react-ga'

import MyLinks from './components/MyLinks/MyLinks'
import { initReactI18next } from 'react-i18next'
import translationsEN from './translations/en.json'
import translationsRU from './translations/ru.json'
import translationsLV from './translations/lv.json'
import './themes.css'

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: translationsEN
      },
      ru: {
        translation: translationsRU
      },
      lv: {
        translation: translationsLV
      }
    },
    lng: 'ru',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false
    }
  })

function App () {
  const [theme, setTheme] = useState(() => {
    const storedTheme = localStorage.getItem('theme')
    if (storedTheme) return storedTheme

    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      return 'dark'
    } else {
      return 'light'
    }
  })

  useEffect(() => {
    document.documentElement.setAttribute('data-theme', theme)
    localStorage.setItem('theme', theme)

    ReactGA.pageview(window.location.pathname + window.location.search)
  }, [theme])

  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === 'light' ? 'dark' : 'light'))
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<MyLinks toggleTheme={toggleTheme} currentTheme={theme} />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App
