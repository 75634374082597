import oriental1 from './../img/oriental1.jpg'
import oriental2 from './../img/oriental2.jpg'

const oriental = [
  {
    heading: 'oriental.article.0.heading',
    imgSrc: oriental1,
    link: 'https://bao-buy.com/',
    myButton: 'oriental.article.0.myButton'
  },
  {
    heading: 'oriental.article.1.heading',
    imgSrc: oriental2,
    link: 'https://t.me/BaoBuyVG',
    myButton: 'oriental.article.1.myButton'
    // @BaoBuyVG
  }
]

export default oriental
