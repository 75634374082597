import myroutes1 from './../img/myroutes1.jpg'
import myroutes2 from './../img/myroutes2.jpg'
import myroutes3 from './../img/myroutes3.jpg'
import myroutes4 from './../img/myroutes4.jpg'

const articles = [
  {
    heading: 'myroutes.article.0.heading',
    imgSrc: myroutes1,
    link: 'https://thenoisetier.com/blog/cappadocia-what-to-see-in-the-fabulous-valley',
    myButton: 'myroutes.article.0.myButton'
  },
  {
    heading: 'myroutes.article.1.heading',
    imgSrc: myroutes2,
    link: 'https://thenoisetier.com/blog/winter-fairytale-in-zillertal-alps',
    myButton: 'myroutes.article.1.myButton'
  },
  {
    heading: 'myroutes.article.2.heading',
    imgSrc: myroutes3,
    link: 'https://thenoisetier.com/blog/what-to-do-in-amsterdam-if-you-are-there-for-the-first-time',
    myButton: 'myroutes.article.2.myButton'
  },
  {
    heading: 'myroutes.article.3.heading',
    imgSrc: myroutes4,
    link: 'https://thenoisetier.com/blog/weekend-in-rome',
    myButton: 'myroutes.article.3.myButton'
  }
]

export default articles
