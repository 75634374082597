import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
// import i18n from './i18n/i18n'
import { useTranslation } from 'react-i18next'
import ReactGA from 'react-ga'

import styles from './Card.module.sass'

function Card (props) {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search)
  }, [])

  const { t } = useTranslation()

  const [isPressed, setIsPressed] = useState(false)

  const handleMouseDown = () => {
    setIsPressed(true)
  }

  const handleMouseUp = () => {
    setIsPressed(false)
  }

  return (
    <a href={props.link}
      className={styles.card}
      style={{ transform: isPressed ? 'scale(0.95)' : 'scale(1)' }}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}
    >
      <img className={styles.image} src={props.image} alt={props.alt} />
      <h3 className={styles.heading}>{t(props.heading)}</h3>
      <div className={styles.link} href={props.link}>
        {t(props.myButton)}
      </div>
    </a>
  )
}

Card.propTypes = {
  image: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  myButton: PropTypes.string.isRequired
}

export default Card
