// import i18n from './i18n/i18n'

import article1 from './../img/article1.jpg'
import article2 from './../img/article2.jpg'
import article3 from './../img/article3.jpg'
import article4 from './../img/article4.png'

const articles = [
  {
    heading: 'articles.article.0.heading',
    imgSrc: article1,
    link: 'https://thenoisetier.com/blog/cappadocia-what-to-see-in-the-fabulous-valley',
    myButton: 'articles.article.0.myButton'
  },
  {
    heading: 'articles.article.1.heading',
    imgSrc: article2,
    link: 'https://thenoisetier.com/blog/winter-fairytale-in-zillertal-alps',
    myButton: 'articles.article.1.myButton'
  },
  {
    heading: 'articles.article.2.heading',
    imgSrc: article3,
    link: 'https://thenoisetier.com/blog/what-to-do-in-amsterdam-if-you-are-there-for-the-first-time',
    myButton: 'articles.article.2.myButton'
  },
  {
    heading: 'articles.article.3.heading',
    imgSrc: article4,
    link: 'https://thenoisetier.com/blog/weekend-in-rome',
    myButton: 'articles.article.3.myButton'
  }
]

export default articles
