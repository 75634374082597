import React, { useEffect, useState } from 'react'
import { useTranslation, I18nextProvider } from 'react-i18next'
import { Helmet } from 'react-helmet'
import ReactGA from 'react-ga'
import PropTypes from 'prop-types'

import Card from './../Card/Card'

import styles from './MyLinks.module.sass'

import vicky from './../../img/vicky.jpg'
import articles from './../articles'
import oriental from './../oriental'
import myroutes from './../myroutes'
import { ReactComponent as TelegramIcon } from './../../icons/telegram.svg'
import { ReactComponent as WhatsappIcon } from './../../icons/whatsapp.svg'

function MyLinks ({ toggleTheme, currentTheme }) {
  const toggleThemeFunction = (newTheme) => {
    toggleTheme(newTheme)
  }

  useEffect(() => {
    const updateThemeBasedOnDevicePreference = (event) => {
      const newTheme = event.matches ? 'dark' : 'light'
      toggleThemeFunction(newTheme)
    }

    const matchMediaObj = window.matchMedia('(prefers-color-scheme: dark)')
    if (matchMediaObj) {
      matchMediaObj.addEventListener('change', updateThemeBasedOnDevicePreference)
    }

    return () => {
      if (matchMediaObj) {
        matchMediaObj.removeEventListener('change', updateThemeBasedOnDevicePreference)
      }
    }
  }, [toggleTheme])

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search)
  }, [])

  const [isTelegramPressed, setIsTelegramPressed] = useState(false)

  const handleMouseDownTelegram = () => {
    setIsTelegramPressed(true)
  }

  const handleMouseUpTelegram = () => {
    setIsTelegramPressed(false)
  }

  const [isWhatsappPressed, setIsWhatsappPressed] = useState(false)

  const handleMouseDownWhatsapp = () => {
    setIsWhatsappPressed(true)
  }

  const handleMouseUpWhatsapp = () => {
    setIsWhatsappPressed(false)
  }

  const { t, i18n } = useTranslation()

  const currentLanguage = i18n.language

  const handleLanguageChange = (lng) => {
    i18n.changeLanguage(lng)
  }

  return (
    <>
      <Helmet>
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="description" content="Victoria Gudovskaya - VoyagesEtMoi blog with beautiful travels, interesting guides and routes, as well as favorite work, thanks to which was able to visit different parts of the world." />
        <meta name="keywords" content="travel, blog, Victoria Gudovskaya, VoyagesEtMoi" />
        <meta name="author" content="Victoria Gudovskaya" />
        <meta property="og:title" content="VoyagesEtMoi" />
        <meta property="og:description" content="Victoria Gudovskaya's VoyagesEtMoi blog is an engaging environment packed with inspiring journeys, insightful guides and itineraries, and remarkable encounters from over the world. Join me as I share my love of exploration and adventure while uncovering the world's hidden jewels via my favorite art." />
        <meta property="og:image" content="https://www.voyagesetmoi.com/vicky.jpg" />
        <meta property="og:url" content="https://www.voyagesetmoi.com/" />
        <meta property="og:type" content="website" />
        <title>VoyagesEtMoi | Victoria Gudovskaya</title>
      </Helmet>
      <I18nextProvider i18n={i18n}>
        <div className={styles['mylinks-wrapper']}>
        <div className={styles['mylinks-themeswitcher']}>
        <button
          className={styles['mylinks-themeButton']}
          onClick={toggleThemeFunction}
        >
          {currentTheme === 'light' ? 'Dark Theme' : 'Light Theme'}
        </button>
        </div>
        <div className={styles['mylinks-langswitcher']}>
    {currentLanguage !== 'en' && (
      <button
        className={styles['mylinks-languageButton']}
        value='en'
        onClick={() => handleLanguageChange('en')}
      >
        English
      </button>
    )}
    {currentLanguage !== 'ru' && (
      <button
        className={styles['mylinks-languageButton']}
        value='ru'
        onClick={() => handleLanguageChange('ru')}
      >
        Русский
      </button>
    )}
    {currentLanguage !== 'lv' && (
      <button
        className={styles['mylinks-languageButton']}
        value='lv'
        onClick={() => handleLanguageChange('lv')}
      >
        Latviešu
      </button>
    )}
  </div>
          <div className={styles['mylinks-headerWrapper']}>
            <div className={styles['mylinks-header']}>
              <img className={styles['mylinks-logo']} src={vicky} alt="Victoria Gudovskaya" />
              <div className={styles['mylinks-headerName']}>
                <h1 className={styles['mylinks-brandname']}>{t('mylinks.brandname')}</h1>
                <span className={styles['mylinks-subtitle']}>{t('mylinks.subtitle')}</span>
              </div>
              <p className={styles['mylinks-text']}>{t('mylinks.text')}</p>
            </div>
          </div>
          <hr className={styles['mylinks-hr']} />
          <h2 className={styles['mylinks-title']}>{t('mylinks.articles.title')}</h2>
          {/* cards go here */}
          <div className={styles['mylinks-articles']}>
            {articles.map((article, index) => (
              <Card key={index} image={article.imgSrc} alt={article.heading} heading={article.heading} link={article.link} myButton={article.myButton} />
            ))}
          </div>
          <hr className={styles['mylinks-hr']} />
          <h2 className={styles['mylinks-title']}>{t('mylinks.oriental.title')}</h2>
          <div className={styles['mylinks-articles']}>
            {oriental.map((article, index) => (
              <Card key={index} image={article.imgSrc} alt={article.heading} heading={article.heading} link={article.link} myButton={article.myButton} />
            ))}
          </div>
          <hr className={styles['mylinks-hr']} />
          <h2 className={styles['mylinks-title']}>{t('mylinks.myroutes.title')}</h2>
          {/* <div className={styles['mylinks-articles']}> */}
          <div className={styles['mylinks-soon']}>
            {myroutes.map((article, index) => (
              <Card key={index} image={article.imgSrc} alt={article.heading} heading={article.heading} link={article.link} myButton={article.myButton} />
            ))}
          </div>
          <hr className={styles['mylinks-hr']} />
          <h2 className={styles['mylinks-title']}>{t('mylinks.contact.title')}</h2>
          <div className={styles['mylinks-contact']}>
            <a className={styles['mylinks-whatsapp']}
              style={{ transform: isTelegramPressed ? 'scale(0.95)' : 'scale(1)' }}
              onMouseDown={handleMouseDownTelegram}
              onMouseUp={handleMouseUpTelegram}
              onMouseLeave={handleMouseUpTelegram}
              href="https://wa.me/+79164492428">
              <svg className={styles['mylinks-svg']} fill={styles['mylinks-svg'].fill}>
                <use xlinkHref="#whatsapp"></use>
                <WhatsappIcon className={styles['mylinks-svg']} />
              </svg>
              <span>{t('mylinks.contact.whatsapp')}</span>
            </a>
            <a className={styles['mylinks-telegram']}
              style={{ transform: isWhatsappPressed ? 'scale(0.95)' : 'scale(1)' }}
              onMouseDown={handleMouseDownWhatsapp}
              onMouseUp={handleMouseUpWhatsapp}
              onMouseLeave={handleMouseUpWhatsapp}
              href="https://t.me/BaoBuyVG">
              <svg className={styles['mylinks-svg']} fill={styles['mylinks-svg'].fill}>
                <use xlinkHref="#telegram"></use>
                <TelegramIcon className={styles['mylinks-svg']} />
              </svg>
              <span>{t('mylinks.contact.telegram')}</span>
            </a>
          </div>
        </div>
      </I18nextProvider>
    </>
  )
}

MyLinks.propTypes = {
  toggleTheme: PropTypes.func.isRequired,
  currentTheme: PropTypes.string.isRequired
}

export default MyLinks
